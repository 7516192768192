import data from "../../shared/data";

export async function getCustomers() {
  const result = await data.get("/Customer");
  return result;
}

export async function getPortsByCustomerNo(x, customerNo) {
  const result = await data.get("/Port/?customerId=" + customerNo);
  return result;
}

export async function getPortsWithLatLong(x, customerNo) {
  const result = await data.get(
    "/Port/?requiredLatLong=true&customerId=" + customerNo
  );
  return result;
}

export async function getOrders() {
  const result = await data.get("/Order");
  return result;
}

export async function getOrdersByPortNumber(x, portNo) {
  const result = await data.get("/Order/?portNumber=" + portNo);
  return result;
}

export async function getPorts() {
  const result = await data.get("/Port");
  return result;
}

export async function getOrdersLines(x, orderNo) {
  const result = await data.get("/OrderLines/?orderNo=" + orderNo);
  return result;
}

export async function getFiles(x, orderNo) {
  const result = await data.get("/Attachment/getFiles/?orderNo=" + orderNo);
  return result;
}
export async function getNotes(x, orderNo) {
  const result = await data.get("/Attachment/getNotes/?orderNo=" + orderNo);
  return result;
}

export async function getCheckListFiles(x, orderNo) {
  const result = await data.get(
    "/Attachment/getCheckLists/?orderNo=" + orderNo
  );
  return result;
}

export async function getFile(x, payload) {
  console.log(payload);
  const result = await data.getFile(
    "/Attachment/getFile/?path=" +
      payload.path +
      "&vbAttachment=" +
      payload.vbAttachment +
      "&fileName=" +
      payload.filename
  );
  return result;
}
export async function getPortImage(x, portNo) {
  const result = await data.getFile(
    "/Attachment/getPortImage/?portNumber=" + portNo
  );
  return result;
}

export async function getCustomer(x, customerNo) {
  const result = await data.get(
    "/Customer/GetCustomer/?customerId=" + customerNo
  );
  return result;
}

export async function login(x, payload) {
  const result = await data.post("/api/AuthManagement/Login", payload);

  return JSON.parse(result);
}

export async function pwReset(x, payload) {
  const result = await data.post("/api/AuthManagement/ForgotPassword", payload);
  return JSON.parse(result);
}
export async function pwChange(x, payload) {
  const result = await data.post("/api/AuthManagement/ResetPassword", payload);

  return JSON.parse(result);
}

export async function setUser({ commit }, user) {
  commit("setUser", user);
}

export async function setToken({ commit }, token) {
  commit("setToken", token);
}

export async function sendContactForm(x, payload) {
  const result = await data.post("/Mail/SendContactForm", payload);
  return JSON.parse(result);
}
