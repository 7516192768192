<template>
  <div>
    <q-card class="Order">
      <q-card-section>
        <q-card-actions align="left">
          <div class="text-h6">Port: {{ portNumber }}</div>
          <div class="q-gutter-md q-ml-none">
            <q-btn
              round
              size="sm"
              icon="mdi-dots-horizontal"
              @click="openMorePortInfoView"
              v-if="portNumber"
            >
            </q-btn>
          </div>
        </q-card-actions>
      </q-card-section>
      <q-separator />

      <q-card-section class="relative-position;">
        <div class="row q-col-gutter-sm">
          <div class="col-6">
            <q-field borderless label="Portnavn" stack-label>
              <template v-slot:control>
                <div class="self-center full-width no-outline" tabindex="0">
                  {{ SelectedPort.portName }}
                </div>
              </template>
            </q-field>
          </div>

          <div class="col-6">
            <q-field borderless label="Adresse" stack-label>
              <template v-slot:control>
                <div class="self-center full-width no-outline" tabindex="0">
                  {{ SelectedPort.fullAdresss }}
                </div>
              </template>
            </q-field>
          </div>
        </div>
        <div class="row q-col-gutter-sm">
          <div class="col-6">
            <q-field borderless label="Høyde" stack-label>
              <template v-slot:control>
                <div class="self-center full-width no-outline" tabindex="0">
                  {{ SelectedPort.height }}
                </div>
              </template>
            </q-field>
          </div>

          <div class="col-6">
            <q-field borderless label="Bredde" stack-label>
              <template v-slot:control>
                <div class="self-center full-width no-outline" tabindex="0">
                  {{ SelectedPort.width }}
                </div>
              </template>
            </q-field>
          </div>
        </div>
        <div style="margin-top: 15px" />
        <div class="row">
          <div class="col-7">
            <q-input
              debounce
              v-model="filter"
              outlined
              dense
              label="Søk"
              style="margin-bottom: 10px"
            ></q-input>
          </div>
          <div class="col-5 self-center">
            <div v-if="portNumber">
              <q-btn
                class="text-primary"
                style="margin-bottom: 10px; margin-left:15px;"
                @click="openSubmitForm()"
              >
                Bestill service/rep</q-btn
              >
            </div>
          </div>
        </div>
        <q-table
          title=""
          row-key="orderNo"
          dense
          :data="orders"
          :columns="orderColumns"
          :filter="filter"
          :pagination.sync="pagination"
          @row-click="onRowClick"
        >
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
                class="text-black"
                style="background-color: #e1e1e1;"
                >{{ col.label }}</q-th
              >
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
      <q-inner-loading :showing="loadOrdersCard">
        <q-spinner-gears size="50px" color="primary"></q-spinner-gears>
      </q-inner-loading>
    </q-card>

    <div style="margin-top: 10px" />
    <q-dialog v-model="showOrderDetails">
      <q-card style="width: 100%; max-width: 1600px;max-height:1100px; ">
        <q-card-section
          class="row items-center q-pb-none"
          style="margin-bottom:10px;"
        >
          <div class="text-h6">
            Ordre:
            {{ selectedLine.orderNo }}
          </div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-separator></q-separator>

        <q-card-section class="q-pt-none" style="margin-top:21px;  ">
          <q-card>
            <q-tabs
              v-model="tab"
              dense
              no-caps
              class="text-grey"
              active-color="dark"
              indicator-color="dark"
              align="justify"
              narrow-indicator
            >
              <q-tab name="lines" label="Ordrelinjer"></q-tab>
              <q-tab name="notes" label="Notater"></q-tab>
              <q-tab name="files" label="Vedlegg"></q-tab>
              <q-tab name="checklist" label="Sjekklister"></q-tab>
            </q-tabs>

            <q-separator></q-separator>

            <q-tab-panels v-model="tab" style="height: 800px" animated>
              <q-tab-panel name="lines">
                <q-table
                  title=""
                  row-key="lineNo"
                  :data="orderLines"
                  :columns="lineColumns"
                  :pagination.sync="paginationOrder"
                >
                  <template v-slot:header="props">
                    <q-tr :props="props">
                      <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        class="text-black"
                        style="background-color: #e1e1e1;"
                        >{{ col.label }}</q-th
                      >
                    </q-tr>
                  </template>
                </q-table>
              </q-tab-panel>

              <q-tab-panel name="notes">
                <q-form class="q-gutter-md">
                  <q-list>
                    <div v-for="note in this.notes" :key="note.lineNo">
                      <q-item>
                        <q-item-section>
                          <q-item-label>
                            <span style="white-space: pre-line">
                              {{ note.note }}
                            </span>
                          </q-item-label>
                          <q-item-label caption
                            >Opprette av: {{ note.createdBy }} -
                            {{ note.created }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-separator spaced inset></q-separator>
                    </div>
                  </q-list>
                </q-form>
              </q-tab-panel>

              <q-tab-panel name="files">
                <q-table
                  title=""
                  row-key="filename"
                  :data="files"
                  :columns="fileColumns"
                  :pagination.sync="paginationOrder"
                >
                  <template v-slot:header="props">
                    <q-tr :props="props">
                      <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        class="text-black"
                        style="background-color: #e1e1e1;"
                        >{{ col.label }}</q-th
                      >
                      <q-th
                        class="text-black"
                        style="background-color: #e1e1e1;"
                      ></q-th>
                    </q-tr>
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                      >
                        {{ col.value }}
                      </q-td>

                      <q-td auto-width>
                        <q-btn
                          color="primary"
                          label="Åpne"
                          @click="openFile(props.row)"
                        ></q-btn>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </q-tab-panel>
              <q-tab-panel name="checklist">
                <q-table
                  title=""
                  row-key="filename"
                  :data="checkLists"
                  :columns="fileColumns"
                  :pagination.sync="paginationOrder"
                >
                  <template v-slot:header="props">
                    <q-tr :props="props">
                      <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        class="text-black"
                        style="background-color: #e1e1e1;"
                        >{{ col.label }}</q-th
                      >
                      <q-th
                        class="text-black"
                        style="background-color: #e1e1e1;"
                      ></q-th>
                    </q-tr>
                  </template>
                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                      >
                        {{ col.value }}
                      </q-td>

                      <q-td auto-width>
                        <q-btn
                          color="primary"
                          label="Åpne"
                          @click="openFile(props.row)"
                        ></q-btn>
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </q-tab-panel>
            </q-tab-panels>
            <q-card-actions align="right" class="text-primary">
              <q-btn flat label="Lukk" v-close-popup></q-btn>
            </q-card-actions>
          </q-card>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div style="margin-top: 10px" />
    <q-dialog v-model="showOrderRepForm">
      <q-card style="width: 100%; max-width: 900px;max-height:1100px; ">
        <q-form @submit="submitForm()">
          <q-card-section
            class="row items-center q-pb-none"
            style="margin-bottom:10px;"
          >
            <div class="text-h6">
              Port:
              {{ portNumber }}
            </div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>
          <q-separator></q-separator>
          <q-card-section>
            <div class="row" style="margin-bottom:20px;">
              <div class="col-6">
                <q-input
                  outlined
                  v-model="contactName"
                  :rules="[(val) => !!val || 'Vennligst fyll ut']"
                  lazy-rules
                  style="margin-right:10px;"
                  label="Kontakt navn"
                />
              </div>
              <div class="col-6">
                <q-input
                  outlined
                  v-model="contactNumber"
                  mask="## ## ## ##"
                  lazy-rules
                  :rules="[
                    (val) => val.length === 11 || 'Skriv inn gyldig tlf',
                  ]"
                  label="Kontakt tlf"
                />
              </div>
            </div>
            <div class="row" style="margin-bottom:20px;">
              <div class="col-6">
                <q-input
                  outlined
                  type="email"
                  :rules="[(val) => !!val || 'Epost mangler', isValidEmail]"
                  v-model="contactEmail"
                  lazy-rules
                  style="margin-right:10px;"
                  label="Kontakt epost"
                />
              </div>
              <div class="col-6">
                <q-select
                  outlined
                  v-model="selectedOption"
                  :rules="[(val) => !!val || 'Velg type']"
                  lazy-rules
                  :options="contactOptions"
                  label="Type henvendelse"
                />
              </div>
            </div>
            <div class="row" v-if="dmeGr1 > 0">
              <div class="col-6">
                <q-input
                  outlined
                  v-model="contactPO"
                  :rules="[(val) => !!val || 'Vennligst fyll ut']"
                  lazy-rules
                  style="margin-right:10px;"
                  label="PO"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <q-input
                  outlined
                  v-model="description"
                  label="Beskrivelse"
                  type="textarea"
                />
              </div>
            </div>
          </q-card-section>

          <q-card-actions
            align="right"
            style="margin-right:9px"
            class="text-primary"
          >
            <q-btn flat type="submit" label="Send henvendelse"></q-btn>
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showPortInfoView">
      <q-card style="width: 100%; max-width: 600px;max-height:1100px; ">
        <q-card-section
          class="row items-center q-pb-none"
          style="margin-bottom:10px;"
        >
          <div class="text-h6">
            Port:
            {{ portNumber }}
          </div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section>
          <div class="row" style="margin-bottom:10px;">
            <div class="col-4">
              <q-field borderless label="Portnavn" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.portName }}
                  </div>
                </template>
              </q-field>
            </div>

            <div class="col-4">
              <q-field borderless label="Kunde beskrivelse" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.customerDescription }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Adresse" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.fullAdresss }}
                  </div>
                </template>
              </q-field>
            </div>
          </div>
          <div class="row" style="margin-bottom:10px;">
            <div class="col-4">
              <q-field borderless label="Høyde" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.height }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Bredde" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.width }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Overhøyd" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.topHeight }}
                  </div>
                </template>
              </q-field>
            </div>
          </div>
          <div class="row" style="margin-bottom:10px;">
            <div class="col-4">
              <q-field borderless label="Innkjøpsnummer" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.cdPurchaseNo }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Kundenavn" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.customerName }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Kundenummer" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.customerNo }}
                  </div>
                </template>
              </q-field>
            </div>
          </div>

          <div class="row" style="margin-bottom:10px;">
            <div class="col-4">
              <q-field borderless label="Merke" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.brand }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Avdeling" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.department }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Beslagstype" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.fittingType }}
                  </div>
                </template>
              </q-field>
            </div>
          </div>
          <div class="row" style="margin-bottom:10px;">
            <div class="col-4">
              <q-field borderless label="Merket" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.portMarked }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Salgspris" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.salesPrice }}
                  </div>
                </template>
              </q-field>
            </div>
            <div class="col-4">
              <q-field borderless label="Prosjekt" stack-label>
                <template v-slot:control>
                  <div class="self-center full-width no-outline" tabindex="0">
                    {{ SelectedPort.project }}
                  </div>
                </template>
              </q-field>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<style></style>

<script>
import { mapActions } from "vuex";
import { date } from "quasar";

export default {
  props: {
    CustomerId: {},
    SelectedPort: {},
  },

  data() {
    return {
      pagination: {
        rowsPerPage: 5, // current rows per page being displayed
        sortBy: "orderNo",
        descending: true,
      },
      paginationOrder: {
        rowsPerPage: 10, // current rows per page being displayed
      },
      orderColumns: [
        {
          name: "orderNo",
          align: "left",
          field: "orderNo",
          label: "Ordrenummer",
          sortable: true,
        },
        {
          name: "orderType",
          align: "left",
          label: "Ordretype",
          field: "orderType",

          sortable: true,
        },
        {
          name: "orderDate",
          align: "left",
          label: "Ord.Dato",
          field: "orderDate",
          format: (val) => date.formatDate(val, "DD-MM-YYYY"),
          sortable: true,
        },
        {
          name: "orderStatus",
          align: "left",
          label: "Ordrestatus",
          field: "orderStatus",

          sortable: true,
        },
      ],

      fileColumns: [
        {
          name: "filename",
          align: "left",
          field: "filename",
          label: "Filnavn",
          sortable: true,
        },
      ],

      lineColumns: [
        {
          name: "productNo",
          align: "left",
          label: "Produktnummer",
          field: "productNo",
          sortable: true,
        },
        {
          name: "description",
          align: "left",
          label: "Beskrivelse",
          field: "description",
          sortable: true,
        },
        {
          name: "originalQuantity",
          align: "left",
          label: "Opprinnelig antall",
          field: "originalQuantity",
          sortable: true,
        },
        {
          name: "employee",
          align: "left",
          label: "Ansatt navn",
          field: "employee",
          sortable: true,
        },

        {
          name: "date",
          align: "left",
          label: "Dato",
          format: (val) => date.formatDate(val, "DD-MM-YYYY"),
          field: "date",
          // format: (val) => date.formatDate(val, "YYYYMMDD"),
          sortable: true,
        },
      ],
      orders: [],
      orderLines: [],
      files: [],
      checkLists: [],
      notes: [],
      filter: "",
      portNumber: "",
      description: "",
      contactName: "",
      contactEmail: "",
      dmeGr1: 0,
      contactNumber: "",
      selectedOption: "",
      contactPO: "",
      selectedLine: {},
      selectedFile: {},
      emailPattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      contactOptions: ["Service", "Reperasjon"],
      tab: "lines",
      loadOrdersCard: false,
      showOrderDetails: false,
      showPortInfoView: false,
      showOrderRepForm: false,
    };
  },

  async mounted() {},

  methods: {
    ...mapActions("main", [
      "getOrders",
      "getCustomer",
      "getFiles",
      "getFile",
      "sendContactForm",
      "getNotes",
      "getCheckListFiles",
      "getOrdersByPortNumber",
      "getOrdersLines",
    ]),

    isValidEmail() {
      return this.emailPattern.test(this.contactEmail) || "Ugyldig epost";
    },

    openMorePortInfoView() {
      console.log(this.SelectedPort);
      this.showPortInfoView = true;
    },

    async getOrdersByPort(portNo) {
      this.loadOrdersCard = true;
      this.orders = await this.getOrdersByPortNumber(portNo);
      this.loadOrdersCard = false;
    },

    async getOrderLinesByOrderNo(orderNo) {
      this.orderLines = await this.getOrdersLines(orderNo);
    },

    async getFilesByOrderNo(orderNo) {
      this.files = await this.getFiles(orderNo);
    },

    async getCustomerSelected(customerNo) {
      var customer = await this.getCustomer(customerNo);
      console.log(customer);
      this.dmeGr1 = customer.dmeGr1;
      console.log(this.dmeGr1);
    },

    async getCheckListsByOrderNo(orderNo) {
      this.checkLists = await this.getCheckListFiles(orderNo);
    },
    async getNotesbyOrderNo(orderNo) {
      this.notes = await this.getNotes(orderNo);
    },

    openSubmitForm() {
      this.showOrderRepForm = true;
    },
    async submitForm() {
      var payload = {};
      payload.portNumber = this.portNumber;
      payload.type = this.selectedOption;
      payload.description = this.description;
      payload.contactName = this.contactName;
      payload.contactNumber = this.contactNumber;
      payload.contactEmail = this.contactEmail;
      payload.contactPO = this.contactPO;
      var message = await this.sendContactForm(payload);

      if (message.result === true) {
        this.selectedOption = "";
        this.description = "";
        this.contactName = "";
        this.contactNumber = "";
        this.contactEmail = "";
        this.contactPO = "";
        this.$q.notify({
          type: "positive",
          message: message.message,
        });
        this.showOrderRepForm = false;
      }
    },

    async openFile(row) {
      console.log(row);
      var res = await this.getFile(row);

      console.log(navigator.userAgent);
      const url = window.URL.createObjectURL(new Blob([res]));
      // var blob = new Blob([res]);
      const link = document.createElement("a");
      link.href = url;
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(blob, "ht.png");
      // }
      if (
        navigator.userAgent.indexOf("Edg") != -1 ||
        navigator.userAgent.indexOf("Chrome") != -1
      ) {
        link.setAttribute("download", row.filename);

        // window.navigator.msSaveBlob(blob, "test");
      } else {
        link.setAttribute("target", "_blank");
      }
      document.body.appendChild(link);
      link.click();
    },

    onRowClick(evt, row) {
      this.selectedLine = row;
      this.getOrderLinesByOrderNo(this.selectedLine.orderNo);
      this.getFilesByOrderNo(this.selectedLine.orderNo);
      this.getCheckListsByOrderNo(this.selectedLine.orderNo);
      this.getNotesbyOrderNo(this.selectedLine.orderNo);
      this.showOrderDetails = true;
    },
  },

  watch: {
    "SelectedPort.portNo": function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getOrdersByPort(newVal);
        console.log(this.SelectedPort.customerNo);
        this.getCustomerSelected(this.SelectedPort.customerNo);
        this.portNumber = newVal;
      }
    },

    CustomerId(newValue, oldValue) {
      if (newValue != oldValue) {
        this.orders = [];
      }
    },
  },
};
</script>
<style>
.Order {
  height: 50vh;
}
</style>
