export default {
  async put(url, data) {
    if (url.indexOf("http://") < 0 && url.indexOf("https://") < 0) {
      var baseUrl = (await (await fetch("static/config.json")).json())
        .API_BASE_URL;
      url = baseUrl + url;
    }

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=utf-8",
        },
        mode: "cors",
        body: data ? JSON.stringify(data) : null,
        credentials: "same-origin",
        cache: "no-cache",
      });

      if (response.ok) {
        if (response.redirected === true) {
          window.top.location.href = response.url.split(/[?#]/)[0];
        }
        return await response.text();
      } else {
        var t = await response.text();
        throw `${response.status} ${response.statusText}: ${t}`;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async post(url, data) {
    if (url.indexOf("http://") < 0 && url.indexOf("https://") < 0) {
      var baseUrl = (await (await fetch("static/config.json")).json())
        .API_BASE_URL;
      url = baseUrl + url;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=utf-8",
        },
        mode: "cors",
        body: data ? JSON.stringify(data) : null,
        credentials: "same-origin",
        cache: "no-cache",
      });
      return await response.text();
      // if (response.ok) {
      //   return await response.text();
      // } else {
      //   var t = await response.text();
      //   throw `${response.status} ${response.statusText}: ${t}`;
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async delete(url) {
    if (url.indexOf("http://") < 0 && url.indexOf("https://") < 0) {
      var baseUrl = (await (await fetch("static/config.json")).json())
        .API_BASE_URL;
      url = baseUrl + url;
    }

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=utf-8",
        },
        mode: "cors",
        credentials: "same-origin",
        cache: "no-cache",
      });

      if (response.ok) {
        if (response.redirected === true) {
          window.top.location.href = response.url.split(/[?#]/)[0];
        }
        return await response.text();
      } else {
        var t = await response.text();
        throw `${response.status} ${response.statusText}: ${t}`;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async get(url) {
    if (url.indexOf("http://") < 0 && url.indexOf("https://") < 0) {
      var baseUrl = (await (await fetch("static/config.json")).json())
        .API_BASE_URL;
      url = baseUrl + url;
    }

    return fetch(url, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw `${response.status} ${response.statusText}`;
        }
        return response.json();
      })
      .catch((error) => {
        console.log("Error: " + error);
        throw error;
      });
  },

  async getFile(url) {
    if (url.indexOf("http://") < 0 && url.indexOf("https://") < 0) {
      var baseUrl = (await (await fetch("static/config.json")).json())
        .API_BASE_URL;
      url = baseUrl + url;
    }

    return fetch(url, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw `${response.status} ${response.statusText}`;
        }
        return response.arrayBuffer();
      })
      .catch((error) => {
        console.log("Error: " + error);
        throw error;
      });
  },
};
