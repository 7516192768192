import { render, staticRenderFns } from "./orders.vue?vue&type=template&id=7a294dc6&"
import script from "./orders.vue?vue&type=script&lang=js&"
export * from "./orders.vue?vue&type=script&lang=js&"
import style1 from "./orders.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QField from 'quasar/src/components/field/QField.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QSelect from 'quasar/src/components/select/QSelect.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QCardActions,QBtn,QSeparator,QField,QInput,QTable,QTr,QTh,QInnerLoading,QSpinnerGears,QDialog,QSpace,QTabs,QTab,QTabPanels,QTabPanel,QForm,QList,QItem,QItemSection,QItemLabel,QTd,QSelect});qInstall(component, 'directives', {ClosePopup});
