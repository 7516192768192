<template>
  <div>
    <div class="q-pa-md">
      <div v-if="showCustomerSelect" class="row">
        <div class="col-3">
          <q-select
            v-model="CustomerId"
            label="Kunde"
            :options="Customers"
            :option-label="
              (opt) =>
                opt === -1
                  ? 'Ikke valgt'
                  : opt.name + ' (' + opt.customerNo + ') '
            "
            @filter="getCustomerFilter"
            option-value="customerNo"
            emit-value
            dense
            map-options
            use-input
            outlined
            :disable="customersLoaded"
            hide-selected
            fill-input
            input-debounce="0"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> Ingen treff </q-item-section>
              </q-item>
            </template>
            <template v-if="CustomerId > 0" v-slot:append>
              <q-icon
                name="cancel"
                @click.stop="CustomerId = -1"
                class="cursor-pointer"
              />
            </template>
          </q-select>
        </div>
        <div col-3 style="margin-left:15px;">
          <q-spinner-gears
            size="45px"
            v-if="customersLoaded"
            color="primary"
          ></q-spinner-gears>
        </div>
      </div>
      <div style="margin-bottom:10px;"></div>
      <div class="row">
        <div class="col-8">
          <Map
            :CustomerId="CustomerId"
            @markerSelected="getOrders"
            :SelectedPort="SelectedPort"
          />
        </div>
        <div class="col-4">
          <Orders :CustomerId="CustomerId" :SelectedPort="SelectedPort" />
        </div>
      </div>
      <div class="row">
        <div class="col-12" style=" margin-top:10px">
          <Ports
            :CustomerId="CustomerId"
            :SelectedMarker="SelectedMarker"
            @rowSelected="getOrders"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Orders from "./orders.vue";
import Map from "./map.vue";
import Ports from "./ports.vue";
import { mapActions } from "vuex";
export default {
  components: {
    Orders,
    Map,
    Ports,
  },
  data() {
    return {
      CustomerId: -1,
      SelectedPort: {},
      SelectedMarker: {},
      Customers: [],
      FilterCustomers: [],
      showCustomerSelect: false,
      customersLoaded: false,
    };
  },
  async mounted() {
    this.customersLoaded = true;
    this.Customers = await this.getCustomers();

    if (this.Customers.length > 1) {
      this.FilterCustomers = await this.getCustomers();
      this.showCustomerSelect = true;
    }
    this.customersLoaded = false;
  },
  methods: {
    ...mapActions("main", ["getCustomers"]),

    getOrders(row) {
      this.SelectedPort = row;
      this.SelectedMarker = row;
    },

    getCustomerFilter(val, update) {
      if (!this.Customers) {
        update();
        return;
      }
      update(() => {
        console.log(this.Customers);
        this.Customers = this.FilterCustomers.filter(
          (p) =>
            p.name.toLowerCase().indexOf(val.toLowerCase()) > -1 ||
            p.customerNo
              .toString()
              .toLowerCase()
              .indexOf(val.toLowerCase()) > -1
        );
      });
    },
  },
};
</script>
