<template>
  <div>
    <q-card class="Port">
      <div class="row"></div>
      <q-card-section style="height: 100%;">
        <div class="col-6">
          <q-input
            debounce
            outlined
            dense
            v-model="filter"
            label="Søk"
            style="margin-bottom: 10px; width:600px;"
          ></q-input>
        </div>
        <q-table
          title=""
          style="height: 85%;"
          virtual-scroll
          :rows-per-page-options="[0]"
          row-key="portNo"
          dense
          :data="ports"
          :columns="columns"
          :filter="filter"
          :pagination.sync="pagination"
          :selected.sync="selected"
          @row-click="onRowClick"
        >
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
                class="text-black"
                style="background-color: #e1e1e1;"
                >{{ col.label }}</q-th
              >
            </q-tr>
          </template>
        </q-table>
        <q-inner-loading :showing="visible">
          <q-spinner-gears size="50px" color="primary"></q-spinner-gears>
        </q-inner-loading>
      </q-card-section>
    </q-card>
  </div>
</template>

<style></style>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    CustomerId: {},
    SelectedPort: {},
    SelectedMarker: {},
  },

  data() {
    return {
      pagination: {
        rowsPerPage: 0,
        sortBy: "portNo",
        descending: true,
      },
      columns: [
        // {
        //   name: "customerNo",
        //   align: "left",
        //   label: "Kunde nr",
        //   field: "customerNo",
        //   sortable: true,
        // },
        {
          name: "customerName",
          align: "left",
          label: "Kunde navn",
          field: "customerName",
          sortable: true,
        },
        {
          name: "portNo",
          align: "left",
          field: "portNo",
          label: "Port nr",
          sortable: true,
        },
        {
          name: "portName",
          align: "left",
          label: "Portnavn",
          field: "portName",
          sortable: true,
        },
        {
          name: "fullAdresss",
          align: "left",
          label: "Adresse",
          field: "fullAdresss",
          sortable: true,
        },
        {
          name: "height",
          align: "left",
          label: "Høyde",
          field: "height",
          sortable: true,
        },
        {
          name: "width",
          align: "left",
          label: "Bredde",
          field: "width",
          sortable: true,
        },
        // {
        //   name: "lat",
        //   align: "left",
        //   label: "Lat",
        //   field: "lat",
        //   sortable: true,
        // },
        // {
        //   name: "lng",
        //   align: "left",
        //   label: "Lng",
        //   field: "lng",
        //   sortable: true,
        // },
      ],
      ports: [],
      selected: [],
      visible: false,
      filter: "",
    };
  },
  async mounted() {
    this.visible = true;
    this.ports = await this.getPorts();
    this.visible = false;
  },
  methods: {
    ...mapActions("main", ["getPorts", "getPortsByCustomerNo"]),

    async getPortsByCustomer(custNo) {
      this.ports = await this.getPortsByCustomerNo(custNo);
    },
    onRowClick(evt, row) {
      this.$emit("rowSelected", row);
      this.selected = [];
      this.selected.push(row);
    },
  },

  watch: {
    CustomerId(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getPortsByCustomer(newValue);
      }
    },

    async SelectedMarker(newValue, oldValue) {
      if (newValue != oldValue) {
        var row = this.ports.find((x) => x.portNo == newValue.portNo);

        this.selected = [];
        this.selected.push(row);
        this.$emit("rowSelected", row);
      }
    },
  },
};
</script>
<style>
.Port {
  height: 34vh;
}
</style>
