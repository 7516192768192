<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-toolbar-title>
          <img src="./assets/logo_white.png" style="margin-top: 6px;" />
        </q-toolbar-title>

        <div>
          <div v-if="isLoggedIn">
            <q-btn-dropdown
              :label="logginUsername"
              rounded
              dropdown-icon="fas fa-user"
            >
              <div class="row no-wrap q-pa-md">
                <div class="column">
                  <div class="text-h6 q-mb-md"></div>
                  <div class="text-h10 q-mb-md">
                    Portal version {{ appVersion }}<br />
                  </div>
                  <!-- <LocaleSwitcher /> -->
                  <!-- <q-toggle
                v-model="darkMode"
                @input="toggleDarkMode()"
                label="Dark mode"
              ></q-toggle> -->
                  <q-btn
                    color="primary"
                    label="Logg ut"
                    push
                    v-close-popup
                    @click="loggOut"
                  ></q-btn>
                </div>
              </div>
            </q-btn-dropdown>
          </div>
        </div>
      </q-toolbar>
    </q-header>
    <div v-if="isLoggedIn">
      <q-page-container>
        <home />
      </q-page-container>
    </div>
    <!-- <div v-if="!isLoggedIn">
      
    </div> -->

    <div
      class="window-height window-width row justify-center items-center"
      v-if="!isLoggedIn && resetPassword && createNewPassword"
    >
      <q-card style="padding:25px;">
        <div class="justify-center q-pa-md">
          <div style="margin-bottom:10px; margin-right:5px;">
            <img src="./assets/windsorlogo.png" />
          </div>
          <q-input
            debounce
            outlined
            v-model="username"
            type="email"
            label="Epost"
            style="margin-bottom:20px; width:400px;"
          ></q-input>

          <q-input
            v-model="password"
            outlined
            style="margin-bottom:20px; width:400px;"
            :type="isPwd ? 'password' : 'text'"
            label="Passord"
            @keydown.enter.prevent="logMeIn"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>

          <div class="col-6 text-center self-center">
            <q-btn
              color="primary"
              style="width:400px; margin-bottom:20px; height:50px;"
              label="Logg inn"
              @click="logMeIn"
            />
          </div>
          <div class="col-6 text-center self-center">
            <q-btn
              color="primary"
              style="width:400px; height:35px;"
              label="Nullstill passord"
              @click="showPasswordResetPage"
            />
          </div>
          <div style="margin-top:10px;">
            <span style="color: black;"> {{ error }}</span>
          </div>
        </div>
      </q-card>
    </div>

    <div
      class="window-height window-width row justify-center items-center"
      v-if="!resetPassword"
    >
      <q-card style="padding:25px;">
        <div class="justify-center q-pa-md">
          <div style="margin-bottom:10px; margin-right:5px;">
            <img src="./assets/windsorlogo.png" />
          </div>
          <q-input
            debounce
            outlined
            v-model="email"
            type="email"
            label="Epost"
            style="margin-bottom:20px; width:400px;"
          ></q-input>

          <div class="col-12 text-center self-center">
            <q-btn
              color="primary"
              style="width:400px; margin-bottom:20px; height:50px;"
              label="Nullstill"
              @click="forgotPW"
            />
          </div>
          <div style="margin-top:10px;">
            <span style="color: black;"> {{ confirmation }}</span>
          </div>
        </div>
      </q-card>
    </div>

    <div
      class="window-height window-width row justify-center items-center"
      v-if="!createNewPassword"
    >
      <q-card style="padding:25px;">
        <div class="justify-center q-pa-md">
          <div style="margin-bottom:10px; margin-right:5px;">
            <img src="./assets/windsorlogo.png" />
          </div>
          <q-input
            v-model="newPassword"
            outlined
            style="margin-bottom:20px; width:400px;"
            :type="isPwd ? 'password' : 'text'"
            label="Nytt passord"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>

          <q-input
            v-model="confirmedPassword"
            outlined
            style="margin-bottom:20px; width:400px;"
            :type="isPwd ? 'password' : 'text'"
            label="Bekreft passord"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>

          <div class="col-12 text-center self-center">
            <q-btn
              color="primary"
              style="width:400px; margin-bottom:20px; height:50px;"
              label="Bekreft"
              @click="changePW"
            />
          </div>
          <div style="margin-top:10px;">
            <span style="color: black;"> {{ resetError }}</span>
          </div>
        </div>
      </q-card>
    </div>
  </q-layout>
</template>

<script>
import home from "./components/home.vue";
import { mapState } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "LayoutDefault",
  computed: {
    ...mapState("main", ["appVersion", "user", "token"]),
  },
  components: {
    home,
  },

  data() {
    return {
      leftDrawerOpen: false,
      isLoggedIn: false,
      resetPassword: true,
      createNewPassword: true,
      username: "",
      password: "",
      newPassword: "",
      confirmedPassword: "",
      email: "",
      isPwd: true,
      error: "",
      resetError: "",
      confirmation: "",
      resetToken: "",
      resetEmail: "",
      logginUsername: "",
    };
  },
  created: function() {
    this.resetToken = new URL(location.href).searchParams.get("token");
    this.resetEmail = new URL(location.href).searchParams.get("email");

    if (this.resetToken) {
      this.createNewPassword = false;
    }

    this.checkIfUserIsLoggedIn();
  },

  methods: {
    ...mapActions("main", ["login", "pwReset", "pwChange"]),

    async checkIfUserIsLoggedIn() {
      if (localStorage.getItem("token")) {
        var parsed = this.parseJwt(localStorage.getItem("token"));

        this.logginUsername = parsed.email;

        var dateNow = Math.round(new Date() / 1000);
        if (parsed.exp > dateNow) {
          this.isLoggedIn = true;
        } else {
          console.log("logget ut");
          this.isLoggedIn = false;
          localStorage.setItem("token", "");
          this.$forceUpdate();
        }

        setTimeout(this.checkIfUserIsLoggedIn, 600000);
      }
    },

    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },

    async logMeIn() {
      try {
        this.error = "";
        var user = {};
        user.email = this.username;
        user.password = this.password;
        var message = await this.login(user);

        if (message.result == true) {
          this.logginUsername = this.username;
          localStorage.setItem("token", message.token);
          this.password = "";
          this.isLoggedIn = true;
        }
        if (message.result == false) {
          this.error = message.message;
        }
      } catch (error) {
        console.log(error);
        this.error = "Feil brukernavn og passord";
      }
    },

    async forgotPW() {
      var user = {};
      user.email = this.email;
      user.clientURI = (
        await (await fetch("static/config.json")).json()
      ).WEB_BASE_URL;

      var message = await this.pwReset(user);

      if (message.result == false) {
        this.error = message.message;
      } else {
        this.error =
          "Epost sendt (husk å sjekke i søppelposten om e-posten ikke dukker opp)";
      }
      this.isLoggedIn = false;
      this.resetPassword = true;
    },

    async changePW() {
      if (this.newPassword == "" || this.confirmedPassword == "") {
        this.resetError = "Passord kan ikke være blankt";
        return;
      }
      if (this.newPassword != this.confirmedPassword) {
        this.resetError = "Passord matcher ikke";
        return;
      }

      if (this.newPassword.length < 5 || this.confirmedPassword.length < 5) {
        this.resetError = "Passord må inneholde 4 tegn eller mer";
        return;
      }

      var pass = {};
      pass.password = this.newPassword;
      pass.confirmPassword = this.confirmedPassword;
      pass.token = this.resetToken;
      pass.email = this.resetEmail;

      var message = await this.pwChange(pass);

      if (message.result == true) {
        this.resetError = "Passord endret";
        setTimeout(() => {
          console.log("Venter!");
        }, 5000);
        window.location.href = location.href.split("?")[0];
      }
      if (message.result == false) {
        this.resetError = "Lenken er ugyldig";
      }
    },

    showPasswordResetPage() {
      this.resetPassword = false;
    },

    loggOut() {
      this.isLoggedIn = false;
      localStorage.setItem("token", "");
    },
  },
};
</script>

<style></style>
<style lang="scss">
body {
}
</style>
