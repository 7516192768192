<template>
  <div class="Map" />
</template>

<style></style>

<script>
import { mapActions } from "vuex";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import gmapsInit from "../utils/gmaps";
export default {
  name: "Map",
  props: {
    CustomerId: {},
    SelectedPort: {},
  },

  data() {
    return {
      locations: [],
      markers: [],
      markerCluster: [],
      google: {},
      geocoder: {},
      selectedMarker: {},
      map: {},
    };
  },

  methods: {
    ...mapActions("main", ["getPortsWithLatLong", "getPortImage"]),

    async getPortsByCustomer(custNo) {
      const ports = await this.getPortsWithLatLong(custNo);
      this.locations = [];
      // ports.forEach((port) => {
      //   const position = {};
      //   position["lat"] = parseFloat(port.lat);
      //   position["lng"] = parseFloat(port.lng);

      //   this.addMarker(position, port.portNo);
      // });

      for (let i = 0; i < ports.length; i++) {
        const position = {};
        position["lat"] = parseFloat(ports[i].lat);
        position["lng"] = parseFloat(ports[i].lng);

        await this.addMarker(position, ports[i].portNo);
      }

      this.markerCluster = new MarkerClusterer(this.map, this.markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      });

      this.map.setCenter(new this.google.maps.LatLng(64.56146, 10.24664));
      this.map.setZoom(5);
    },

    deleteMarkers() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = new Array();
      this.markerCluster.clearMarkers();
    },

    markerClick(marker, markerClicked) {
      this.map.setZoom(17);
      this.map.setCenter(marker.getPosition());

      marker.infowindow.open(this.map, marker);
      this.selectedMarker = marker;
      if (markerClicked == 1) {
        const clickedMarker = {
          portNo: marker.id,
        };

        this.$emit("markerSelected", clickedMarker);
      }
    },

    async addMarker(position, id) {
      // var res = await this.getPortImage(490494);
      // const url = window.URL.createObjectURL(new Blob([res]));
      if (!this.google.maps) {
        setTimeout(function() {
          console.log("I was waiting for two seconds!");
        }, 3000);
      }
      var marker = new this.google.maps.Marker({
        map: this.map,
        id: id,
        position: position,
      });

      marker.infowindow = new this.google.maps.InfoWindow({
        content:
          '<div id="content">' +
          '<div id="siteNotice">' +
          "</div>" +
          '<div id="bodyContent"> PORT: ' +
          id,
        // "</div>" +
        // "<img src=" +
        // url +
        // ">" +
        // "</div>"
        maxWidth: 1300,
      });
      marker.addListener("click", () => this.markerClick(marker, 1));

      this.markers.push(marker);
    },

    async loadMap() {
      try {
        this.google = await gmapsInit();

        var myOptions = {
          center: new this.google.maps.LatLng(64.56146, 10.24664),
          zoom: 5,
        };

        this.map = new this.google.maps.Map(this.$el, myOptions);
      } catch (error) {
        console.error(error);
      }
      this.getPortsByCustomer(0);
    },
  },
  watch: {
    CustomerId(newValue, oldValue) {
      if (newValue != oldValue) {
        this.deleteMarkers();
        this.getPortsByCustomer(newValue);
      }
    },
    "SelectedPort.portNo": function(newVal, oldVal) {
      if (newVal != oldVal) {
        var m = this.markers.find((x) => x.id == newVal);
        if (!m) {
          return;
        }
        if (Object.keys(this.selectedMarker).length > 0) {
          this.selectedMarker.infowindow.close();
        }
        this.markerClick(m, 0);
      }
    },
  },

  async mounted() {
    this.loadMap();
  },
};
</script>
<style>
.Map {
  height: 50vh;
  margin-right: 15px;
}
</style>
